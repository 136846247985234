import { API_URL } from "../config";

export const FETCH_HOME_DATA_REQUEST = 'FETCH_HOME_DATA_REQUEST';
export const FETCH_HOME_DATA_SUCCESS = 'FETCH_HOME_DATA_SUCCESS';
export const FETCH_HOME_DATA_FAILURE = 'FETCH_HOME_DATA_FAILURE';

export const fetchHomeData = () => async (dispatch, getState) => {
  const { home } = getState();
  if (home.fetched) {
    return;
  }

  dispatch({ type: FETCH_HOME_DATA_REQUEST });

  try {
    const response = await fetch(API_URL+'content/home');
    const data = await response.json();
    dispatch({ type: FETCH_HOME_DATA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FETCH_HOME_DATA_FAILURE, payload: error });
  }
};
