export const activityDetailReducer = (state = { content: [], loading: true, dataLoaded: false }, action) => {
    switch (action.type) {
      case 'ACTIVITY_DETAIL_REQUEST':
        return { ...state, loading: true };
      case 'ACTIVITY_DETAIL_SUCCESS':
        return { 
          ...state, 
          loading: false, 
          content: action.payload,
          dataLoaded: true
        };
      case 'ACTIVITY_DETAIL_FAIL':
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  