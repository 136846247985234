// cartReducer.js

const initialState = {
  orderItems: [],
  orderList: [],
  loading: false,
  error: null,
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ORDER_DETAIL_REQUEST':
      return {
        ...state,
        loading: true,
      };

    case 'ORDER_DETAIL_SUCCESS':
      return {
        ...state,
        loading: false,
        orderItems: action.payload.result,
      };

    case 'ORDER_DETAIL_FAIL':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case 'ORDER_LIST_REQUEST':
      return {
        ...state,
        loading: true,
      };

    case 'ORDER_LIST_SUCCESS':
      return {
        ...state,
        loading: false,
        orderList: action.payload.result,
      };

    case 'ORDER_LIST_FAIL':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case 'ORDER_ADD_REQUEST':
      return {
        ...state,
        loading: true,
      };

    case 'ORDER_ADD_SUCCESS':
      return {
        ...state,
        loading: false,
        orderItems: [...state.orderItems, action.payload.result],
      };

    case 'ORDER_ADD_FAIL':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
