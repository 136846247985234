// src/reducers/menuReducer.js
import {
  FETCH_MENU_DATA_REQUEST,
  FETCH_MENU_DATA_SUCCESS,
  FETCH_MENU_DATA_FAILURE
} from '../actions/menuActions';

const initialState = {
  loading: false,
  data: null,
  error: null,
  fetched: false,
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MENU_DATA_REQUEST:
      return { ...state, loading: true };
    case FETCH_MENU_DATA_SUCCESS:
      return { ...state, loading: false, data: action.payload, fetched: true };
    case FETCH_MENU_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default menuReducer;
