import axios from 'axios';
import { API_URL } from "../config";

export const COUNTRY_LIST_REQUEST = 'COUNTRY_LIST_REQUEST';
export const COUNTRY_LIST_SUCCESS = 'COUNTRY_LIST_SUCCESS';
export const COUNTRY_LIST_FAIL = 'COUNTRY_LIST_FAIL';

export const listCountries = (callbacks = {}) => async (dispatch) => {
    const { onSuccess, onError } = callbacks;

    try {
        dispatch({ type: COUNTRY_LIST_REQUEST });

        const { data } = await axios.get(`${API_URL}country-list`);

        dispatch({ type: COUNTRY_LIST_SUCCESS, payload: data.result });
        if (onSuccess) onSuccess(data);
    } catch (error) {
        dispatch({
            type: COUNTRY_LIST_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
        if (onError) onError(error.message);
    }
};
