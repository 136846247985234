// cartReducer.js

const initialState = {
    cartItems: [],
    cartCount: 0, // Add cartCount to initial state
    loading: false,
    error: null,
  };
  
  export const cartReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'CART_LIST_REQUEST':
        return {
          ...state,
          loading: true,
        };
  
      case 'CART_LIST_SUCCESS':
        return {
          ...state,
          loading: false,
          cartItems: action.payload.result,
          cartCount: action.payload.result.reduce((total, item) => total + 1, 0), // Update cartCount
        };
  
      case 'CART_LIST_FAIL':
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case 'CART_ADD_REQUEST':
        return {
          ...state,
          loading: true,
        };
  
      case 'CART_ADD_SUCCESS':
        return {
          ...state,
          loading: false,
          cartItems: [...state.cartItems, action.payload.result],
          cartCount: state.cartCount + 1,
        };
  
      case 'CART_ADD_FAIL':
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case 'CART_UPDATE_QTY_REQUEST':
        return {
          ...state,
          loading: true,
        };
  
        case 'CART_UPDATE_QTY_SUCCESS':
          const updatedCartItems = state.cartItems.map((item) =>
              item.cart_id === action.payload.result.cart_id
                  ? { ...item, qty: action.payload.result.qty }
                  : item
          );

          return {
              ...state,
              loading: false,
              cartItems: updatedCartItems,
              cartCount: updatedCartItems.reduce((total, item) => total + 1, 0), // Update cartCount
          };
  
      case 'CART_UPDATE_QTY_FAIL':
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case 'CART_DELETE_REQUEST':
        return {
          ...state,
          loading: true,
        };
  
        case 'CART_DELETE_SUCCESS':
          const remainingCartItems = state.cartItems.filter(
              (item) => item.cart_id !== action.payload.result.cart_id
          );

          return {
              ...state,
              loading: false,
              cartItems: remainingCartItems,
              cartCount: remainingCartItems.reduce((total, item) => total + 1, 0), // Update cartCount
          };
  
      case 'CART_DELETE_FAIL':
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  