import { FETCH_CONTENT_REQUEST, FETCH_CONTENT_SUCCESS, FETCH_CONTENT_FAILURE } from "../actions/worldOfFitsparkActions";

const initialState = {
  loading: false,
  content: [],
  error: "",
};

const worldOfFitsparkReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTENT_REQUEST:
      return { ...state, loading: true };
    case FETCH_CONTENT_SUCCESS:
      return { ...state, loading: false, content: action.payload };
    case FETCH_CONTENT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default worldOfFitsparkReducer;
