export const brandHeroesReducer = (state = { content: [], loading: true, dataLoaded: false }, action) => {
    switch (action.type) {
      case 'BRAND_HEROES_REQUEST':
        return { ...state, loading: true };
      case 'BRAND_HEROES_SUCCESS':
        return { 
          ...state, 
          loading: false, 
          content: action.payload,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          dataLoaded: true
        };
      case 'BRAND_HEROES_FAIL':
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  