const contactFormReducer = (state = {}, action) => {
    switch (action.type) {
      case 'CONTACT_FORM_REQUEST':
        return { loading: true };
      case 'CONTACT_FORM_SUCCESS':
        return { loading: false, success: true, data: action.payload };
      case 'CONTACT_FORM_FAIL':
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default contactFormReducer;
  