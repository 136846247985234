import { API_URL } from "../config";

export const FETCH_MENU_DATA_REQUEST = 'FETCH_MENU_DATA_REQUEST';
export const FETCH_MENU_DATA_SUCCESS = 'FETCH_MENU_DATA_SUCCESS';
export const FETCH_MENU_DATA_FAILURE = 'FETCH_MENU_DATA_FAILURE';

export const fetchMenuData = () => async (dispatch, getState) => {
    const { menu } = getState();
    if (menu.fetched) {
        return;
    }

    dispatch({ type: FETCH_MENU_DATA_REQUEST });

    try {
        const response = await fetch(API_URL + 'content/menu');
        const data = await response.json();
        dispatch({ type: FETCH_MENU_DATA_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: FETCH_MENU_DATA_FAILURE, payload: error });
    }
};
