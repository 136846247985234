import {
  FETCH_FOOTER_DATA_REQUEST,
  FETCH_FOOTER_DATA_SUCCESS,
  FETCH_FOOTER_DATA_FAILURE
} from '../actions/footerActions';

const initialState = {
  loading: false,
  data: null,
  error: null,
  fetched: false,
};

const footerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FOOTER_DATA_REQUEST:
      return { ...state, loading: true };
    case FETCH_FOOTER_DATA_SUCCESS:
      return { ...state, loading: false, data: action.payload, fetched: true };
    case FETCH_FOOTER_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default footerReducer;
