// src/store.js
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import homeReducer from './reducers/homeReducer';
import footerReducer from './reducers/footerReducer';
import { userRegisterReducer, userLoginReducer, changePasswordReducer, userReadReducer, userUpdateProfileReducer, forgotPasswordReducer, resetPasswordReducer } from './reducers/userReducer';
import { addressListReducer, addressManageReducer } from './reducers/addressReducers';
import { countryListReducer } from './reducers/countryReducer';
import menuReducer from './reducers/menuReducer';
import worldOfFitsparkReducer from "./reducers/worldOfFitsparkReducer";
import { brandHeroesReducer } from './reducers/brandHeroesReducer';
import { blogsReducer, blogDetailsReducer } from './reducers/blogsReducer';
import { productsReducer, productDetailsReducer } from './reducers/productReducer';
import { pageDetailsReducer } from './reducers/pageReducer';
import contactFormReducer from "./reducers/contactReducer";
import { faqsReducer } from "./reducers/faqsReducer";
import { activityDetailReducer } from "./reducers/activityDetailReducer";
import { downloadReducer } from "./reducers/downloadReducer";
import { cartReducer } from './reducers/cartReducer';
import { orderReducer } from './reducers/orderReducer';

const rootReducer = combineReducers({
  home: homeReducer,
  footer: footerReducer,
  userRegister: userRegisterReducer,
  userRead: userReadReducer,
  userLogin: userLoginReducer,
  changePassword: changePasswordReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  userUpdateProfile: userUpdateProfileReducer,
  addressList: addressListReducer,
  addressManage: addressManageReducer,
  countryList: countryListReducer,
  menu: menuReducer,
  worldOfFitspark: worldOfFitsparkReducer,
  brandHeroes: brandHeroesReducer,
  faqs: faqsReducer,
  activityDetail: activityDetailReducer,
  downloads: downloadReducer,
  blogs: blogsReducer,
  blogDetails: blogDetailsReducer,
  products: productsReducer,
  productDetails: productDetailsReducer,
  pageDetails: pageDetailsReducer,
  contactForm: contactFormReducer,
  cart: cartReducer,
  order: orderReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
