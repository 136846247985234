import axios from "axios";
import { API_URL } from "../config";

export const FETCH_CONTENT_REQUEST = "FETCH_CONTENT_REQUEST";
export const FETCH_CONTENT_SUCCESS = "FETCH_CONTENT_SUCCESS";
export const FETCH_CONTENT_FAILURE = "FETCH_CONTENT_FAILURE";

export const fetchContent = () => async (dispatch) => {
  dispatch({ type: FETCH_CONTENT_REQUEST });
  try {
    const response = await axios.get(`${API_URL}content/world-of-fitspark`);
    dispatch({ type: FETCH_CONTENT_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_CONTENT_FAILURE, payload: error.message });
  }
};
