import axios from 'axios';
import { API_URL } from '../config';

export const ADDRESS_LIST_REQUEST = 'ADDRESS_LIST_REQUEST';
export const ADDRESS_LIST_SUCCESS = 'ADDRESS_LIST_SUCCESS';
export const ADDRESS_LIST_FAIL = 'ADDRESS_LIST_FAIL';

export const ADD_ADDRESS_REQUEST = 'ADD_ADDRESS_REQUEST';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_FAIL = 'ADD_ADDRESS_FAIL';

export const EDIT_ADDRESS_REQUEST = 'EDIT_ADDRESS_REQUEST';
export const EDIT_ADDRESS_SUCCESS = 'EDIT_ADDRESS_SUCCESS';
export const EDIT_ADDRESS_FAIL = 'EDIT_ADDRESS_FAIL';

export const DELETE_ADDRESS_REQUEST = 'DELETE_ADDRESS_REQUEST';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_FAIL = 'DELETE_ADDRESS_FAIL';

export const listAddresses = (callbacks) => async (dispatch) => {
    const { onSuccess, onError } = callbacks || {};
    try {
        dispatch({ type: ADDRESS_LIST_REQUEST });

        const { data } = await axios.get(`${API_URL}user/address_list`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        });
        if (data.status) {
            dispatch({ type: ADDRESS_LIST_SUCCESS, payload: data.result });
            if (onSuccess) onSuccess(data.result);
        } else {
            dispatch({ type: ADDRESS_LIST_FAIL, payload: data.message });
            if (onError) onError(data.message);
        }
    } catch (error) {
        dispatch({
            type: ADDRESS_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
        if (onError) onError(error);
    }
};

export const addAddress = (address, callbacks) => async (dispatch) => {
    const { onSuccess, onError } = callbacks || {};
    try {
        dispatch({ type: ADD_ADDRESS_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        };

        const { data } = await axios.post(`${API_URL}user/add-address`, address, config);
        if (data.status) {
            dispatch({ type: ADD_ADDRESS_SUCCESS, payload: data });
            dispatch(listAddresses());
            if (onSuccess) onSuccess(data);
        } else {
            dispatch({ type: ADD_ADDRESS_FAIL, payload: data.message });
            if (onError) onError(data.message);
        }
    } catch (error) {
        dispatch({
            type: ADD_ADDRESS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
        if (onError) onError(error);
    }
};

export const editAddress = (address, callbacks) => async (dispatch) => {
    const { onSuccess, onError } = callbacks || {};
    try {
        dispatch({ type: EDIT_ADDRESS_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        };

        const { data } = await axios.put(`${API_URL}user/edit-address`, address, config);
        if (data.status) {
            dispatch({ type: EDIT_ADDRESS_SUCCESS, payload: data });
            dispatch(listAddresses());
            if (onSuccess) onSuccess(data);
        } else {
            dispatch({ type: EDIT_ADDRESS_FAIL, payload: data.message });
            if (onError) onError(data.message);
        }
    } catch (error) {
        console.log("error", error.response.data.message);
        dispatch({
            type: EDIT_ADDRESS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
        if (onError) onError(error.response.data.message);
    }
};

export const deleteAddress = (id, callbacks) => async (dispatch) => {
    const { onSuccess, onError } = callbacks || {};
    try {
        dispatch({ type: DELETE_ADDRESS_REQUEST });

        const { data } = await axios.delete(`${API_URL}user/delete-address/${id}`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        });

        if (data.status) {
            dispatch({ type: DELETE_ADDRESS_SUCCESS });
            dispatch(listAddresses());
            if (onSuccess) onSuccess();
        } else {
            dispatch({ type: DELETE_ADDRESS_FAIL, payload: data.message });
            if (onError) onError(data.message);
        }
    } catch (error) {
        dispatch({
            type: DELETE_ADDRESS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
        if (onError) onError(error);
    }
};
