import {
    ADDRESS_LIST_REQUEST,
    ADDRESS_LIST_SUCCESS,
    ADDRESS_LIST_FAIL,
    ADD_ADDRESS_REQUEST,
    ADD_ADDRESS_SUCCESS,
    ADD_ADDRESS_FAIL,
    EDIT_ADDRESS_REQUEST,
    EDIT_ADDRESS_SUCCESS,
    EDIT_ADDRESS_FAIL,
    DELETE_ADDRESS_REQUEST,
    DELETE_ADDRESS_SUCCESS,
    DELETE_ADDRESS_FAIL
} from '../actions/addressActions';

export const addressListReducer = (state = { addresses: [] }, action) => {
    switch (action.type) {
        case ADDRESS_LIST_REQUEST:
            return { loading: true, addresses: [] };
        case ADDRESS_LIST_SUCCESS:
            return { loading: false, addresses: action.payload };
        case ADDRESS_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const addressManageReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_ADDRESS_REQUEST:
        case EDIT_ADDRESS_REQUEST:
        case DELETE_ADDRESS_REQUEST:
            return { loading: true };
        case ADD_ADDRESS_SUCCESS:
        case EDIT_ADDRESS_SUCCESS:
        case DELETE_ADDRESS_SUCCESS:
            return { loading: false, success: true };
        case ADD_ADDRESS_FAIL:
        case EDIT_ADDRESS_FAIL:
        case DELETE_ADDRESS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
