export const blogsReducer = (state = { content: [], loading: true, dataLoaded: false }, action) => {
    switch (action.type) {
      case 'BRAND_BLOGS_REQUEST':
        return { ...state, loading: true };
      case 'BRAND_BLOGS_SUCCESS':
        return { 
          ...state, 
          loading: false, 
          content: action.payload,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          dataLoaded: true
        };
      case 'BRAND_BLOGS_FAIL':
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const blogDetailsReducer = (state = { content: [], loading: true, dataLoaded: false }, action) => {
    switch (action.type) {
      case 'BRAND_BLOGS_DETAILS_REQUEST':
        return { ...state, loading: true };
      case 'BRAND_BLOGS_DETAILS_SUCCESS':
        return { 
          ...state, 
          loading: false, 
          content: action.payload,
          dataLoaded: true
        };
      case 'BRAND_BLOGS_DETAILS_FAIL':
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  